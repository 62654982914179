import React from 'react'
import './GoogleText.scss'

const GOOGLE_PATTERN = [
  `blue`,
  `red`,
  `yellow`,
  `blue`,
  `green`,
  `red`,
]

interface Props {
  children: string,
}

const GoogleText: React.FC<Props> = ({
  children,
}) => {
  const words = children.split(` `)

  if(words.length > 1){
    // TODO: implement
  }

  const letters = words[0].split(``)
  return (
    <span className="google-text">
      {
        letters.map((l, i) => (
          <span
            className={
              GOOGLE_PATTERN[
                (i + 1) === GOOGLE_PATTERN.length
                  ? GOOGLE_PATTERN.length - 1
                  : (i + 1) % GOOGLE_PATTERN.length - 1
              ]
            }
            key={i}
          >
            {l}
          </span>
        ))
      }
    </span>
  )
}

export default GoogleText