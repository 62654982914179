import React from 'react'
import { IoIosWarning } from 'react-icons/io'
import './Warning.scss'

interface Props {
  title?: string,
  children: React.ReactNode
}

const Warning: React.FC<Props> = ({ title = `Warning`, children }) => (
  <div className="warning-container">
    <div className="left">
      <IoIosWarning size="2rem" />
    </div>
    <div className="right">
      <strong>{title}</strong>
      {
        typeof children === `string`
          ? <p>{children}</p>
          : children
      }
    </div>
  </div>
)

export default Warning