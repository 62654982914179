import qs from 'qs'
import React, { useCallback } from 'react'
import { IoLogoMastodon } from 'react-icons/io5'
import { SecondaryButton } from '../Button'
import './Mastodon.scss'

interface Props {
  text: string,
  url: string,
  hashtags?: string,
  via?: string,
}

const MastodonButton: React.FC<Props> = ({
  text,
  url,
  hashtags,
  via,
}) => {
  const onClick = useCallback(() => {
    const homeInstance = window.prompt("What is your home instance?")
    if (!homeInstance) {
      return
    }
    const parameters = qs.stringify({
      text: `${text} \n\n ${url} \n\n#UnicornValley`,
    })
    const href = `https://${homeInstance}/share?${parameters}`
    window.open(href,`_blank`)
  }, [hashtags, text, url, via])
  return (
    <SecondaryButton className="mastodon-share-button" onClick={onClick}>
      <IoLogoMastodon size="1.1em" />&nbsp;TOOT
    </SecondaryButton>
  )
}

export default MastodonButton