import Buttons from './Buttons'
import FacebookButton from './Facebook'
import LinkedinButton from './Linkedin'
import ShareButton from './Share'
import TwitterButton from './Twitter'

export default {
  Buttons,
  Facebook: FacebookButton,
  Linkedin: LinkedinButton,
  Share: ShareButton,
  Twitter: TwitterButton,
}