import qs from 'qs'
import React, { useCallback } from 'react'
import { IoLogoLinkedin } from 'react-icons/io'
import { SecondaryButton } from '../Button'
import './Linkedin.scss'

interface Props {
  mini?: boolean,
  title: string,
  url: string,
  summary: string,
  source?: string,
}

const LinkedinButton: React.FC<Props> = ({
  mini = true,
  title,
  url,
  summary,
  source,
}) => {

  const onClick = useCallback(() => {
    const parameters = qs.stringify({
      mini,
      source,
      summary,
      title,
      url,
    })
    window.open(
      `https://www.linkedin.com/shareArticle?${parameters}`,
      `_blank`,
    )
  }, [mini, source, summary, title, url])

  return (
    <SecondaryButton className="linkedin-share-button" onClick={onClick}>
      <IoLogoLinkedin size="1.1em" />&nbsp;SHARE
    </SecondaryButton>
  )
}

export default LinkedinButton