import { RouteComponentProps } from '@reach/router'
import React, { useState } from 'react'
import Layout from '../../../components/Layout'
import SEO from '../../../components/SEO'
import ShareButton from '../../../components/ShareButton'
import { Warning } from '../../../components/Typography'
import UserProfile from '../../../components/UserProfile'
import Api from '../../../lib/Api'
import Markdown from '../../../lib/Markdown'
import type { Company, RawCompany, User } from '../../../types/unicornvalley'
import { isSafeURL } from '../../../lib/Utils'
import './CompaniesDetail.scss'

interface Props extends RouteComponentProps {
  // eslint-disable-next-line quotes
  id?: Company["id"],
  pageContext?: {
    company: RawCompany,
  }
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const CompaniesDetailPage: React.FC<Props> = (props) => {
  // const isSSR = !!props?.pageContext?.company
  // const companyId = props?.pageContext?.company?.company_id || props.id
  const [
    {
      name,
      tagline,
      picture,
      description,
      link,
      creator = {} as User,
      isRemoved,
    },
    _,
  ] = useState({
    ...Api.parseCompany(props?.pageContext?.company || {} as RawCompany),
  } as Company)

  // useEffect(() => {
  //   (async () => {
  //     if(isSSR){
  //       try {
  //         setCompany(await Api.getCompany(companyId))
  //       } catch (error) {
  //         window.location.replace(`/404`)
  //       }
  //     }
  //     // !isSSR && setIsLoading(true)
      
  //     // setIsLoading(false)
  //   })()
  // }, [isSSR, companyId])

  const url = typeof window !== `undefined` && window.location.href

  return (
    <Layout>
      <SEO
        title={name}
        description={`${tagline}. ${description}`}
        image={picture}
        type="product"
      />
      <div id="companies-detail-page">
        <div className="top-info">
          <div style={{ backgroundImage: `url("${picture}")` }} />
          <div className="content">
            <h3>
              {
                (link && link.length > 0 && isSafeURL(link))
                  ? <a href={link}>{name}</a>
                  : name
              }
            </h3>
            <p>{tagline}</p>
          </div>
        </div>
        <div className="company">
          {
            isRemoved
              ? (
                <Warning>This company is in stealth mode and will not appear on the main page.</Warning>
              ) : null
          }
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: Markdown.render(description),
            }}
          />
          <UserProfile user={creator} />
          <ShareButton.Buttons
            title={`${name} - ${tagline}`}
            description={description}
            url={url}
          />
        </div>
      </div>
    </Layout>
  )
}

export default CompaniesDetailPage

// export const query = graphql(`
//   query()
// `)