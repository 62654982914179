import React from 'react'
import './FacebookText.scss'

interface Props {
  children: string,
}

const FacebookText: React.FC<Props> = ({ children }) => (
  <span className="facebook-text">
    {children}
  </span>
)

export default FacebookText